import { Button, rem } from "@mantine/core";
import { ChevronsIconRight, LocationIcon } from "assets/icon";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGetValidateWebLinkQuery } from "store/services/ticketServices";
import Ellipse1 from "assets/icon/Ellipse1.svg";
import Ellipse2 from "assets/icon/Ellipse2.svg";
import { LanguageSelector, Loader } from "components";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const storage = id;
  window.localStorage.setItem("ticksID", id);

  const { data, isLoading } = useGetValidateWebLinkQuery(storage);

  const apiData = data?.data?.data?.businessDetail?.myBusinessProfile;

  // Handle button click to navigate to the service page
  const handleNavigate = () => {
    setLoading(true); // Show loader
    setTimeout(() => {
      navigate(`/service/${storage}`); // Navigate after 3 seconds
    }, 3000); // 3 seconds delay
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          //   justifyContent: "space-between", // Distribute space between content and SVGs
          height: "100vh", // Full viewport height
          textAlign: "center",
          backgroundColor: "#f5f5f5",
          overflow: "hidden", // Prevent scrolling
          position: "relative", // For absolute positioning of SVGs
        }}
      >
        {/* Main Content */}
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "10px",
            zIndex: 7,
          }}
        >
          <LanguageSelector />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 0.9, // Take up remaining space
            paddingBottom: rem(120),
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {/* Round Image Preview */}

              <div
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "4px solid #ddd",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={apiData?.logo} // Replace with your image URL
                  alt="Service Preview"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>

              {/* Service Name */}
              <h1 style={{ margin: "0", fontSize: "24px", fontWeight: "bold" }}>
                {apiData?.businessName}
              </h1>

              {/* Service Address */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px", // Adds space between the icon and the address
                  margin: "10px 0",
                }}
              >
                <LocationIcon h={15} w={15} color="#F64E60" /> {/* Icon */}
                <p style={{ fontSize: "14px", color: "#666", margin: "0" }}>
                  {apiData?.address?.address}
                </p>
              </div>
            </>
          )}
        </div>

        {/* SVGs Section (Sticky to Bottom) */}
        <div
          style={{
            position: "absolute", // Stick to the bottom
            bottom: "0", // Align to the bottom
            left: "0",
            right: "0",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              maxWidth: "100%",
              height: "90px", // Adjust height as needed
            }}
          >
            {/* Ellipse 1 */}
            <img
              src={Ellipse1}
              alt="Ellipse 1"
              style={{
                position: "absolute",
                bottom: "0",
                left: "50%",
                transform: "translateX(-50%)", // Center horizontally
                width: "100%", // Full width
                maxHeight: "100vh", // Adjust height as needed
                opacity: "0.7",
                zIndex: 1,
              }}
            />

            {/* Ellipse 2 */}
            <img
              src={Ellipse2}
              alt="Ellipse 2"
              style={{
                position: "absolute",
                bottom: "5px", // Overlap by 20px
                left: "50%",
                transform: "translateX(-50%)", // Center horizontally
                width: "100%", // Full width
                maxHeight: "100vh", // Adjust height as needed
                opacity: "0.7",
                zIndex: 2,
              }}
            />

            {loading ? (
              <div
                style={{
                  position: "absolute",
                  bottom: "200px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 3,
                }}
              >
                <Loader /> {/* Display loader when loading is true */}
              </div>
            ) : (
              <Button
                onClick={handleNavigate}
                variant="filled"
                color="dark"
                style={{
                  position: "absolute",
                  fontSize: "18px",
                  width: "370px",
                  borderRadius: "8px",
                  bottom: rem(180),
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: 3,
                  // height: rem(55),
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {t("book-your-appointment")}
                {"  "}
                <ChevronsIconRight w={22} h={22} />
              </Button>
            )}
            <div
              style={{
                position: "absolute",
                fontSize: "12px",
                fontWeight: "500",
                zIndex: 6, // Ensure the footer is above the SVGs
                width: "100%",
                textAlign: "center",
                top: rem(-80),
              }}
            >
              Powered by
              <a
                href="https://bookzyme.com"
                rel="noreferrer"
                target="_blank"
                style={{ color: "#F64E60" }}
              >
                {" "}
                BookzyMe
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
